import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import ProjectThumb from '~components/ProjectThumb'
import Logo from '~components/Logo'
import { BrandImage } from '~components/Svg'
import { BlurFilter } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import { tablet, mobile } from '~styles/global'

const IndexPage = ({ data }) => {
	const { title, content } = data.page
	const [currentThumb, setCurrentThumb] = useState(-1)
	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState, 
			projectTitleLocked: false,
			projectTitleSticky: false,
		}))
	}, [])

	useEffect(() => {
		if(siteState.projectTitleLocked) return null
		if(currentThumb > -1){
			const firstProject = content?.modules[currentThumb].project
			setProjectTitle([firstProject?.title, firstProject?.content?.subtitle, firstProject?.slug?.current])
		} else {
			setProjectTitle(null)
		}
	}, [currentThumb, siteState.projectTitleLocked])

	const setProjectTitle = projectTitle => {
		setSiteState(prevState => ({...prevState, projectTitle}))
	}

	const refs = useRef(content?.modules.map(React.createRef))

	useEffect(() => {
		const updateCurrent = () => {
			const thumbs = refs?.current?.map(ref => ref?.current?.getBoundingClientRect()) || []
			const onPage = thumbs.filter(thumb => thumb?.bottom > 100 && thumb?.top < window.innerHeight)
			const amountsOnPage = onPage.map(item => {
				const topAmount = Math.abs(item.top < 0 ? item.top : 0)
				const bottomAmount = item.bottom > window.innerHeight ? item.bottom - window.innerHeight : 0
				const totalAmount = topAmount + bottomAmount
				const totalPercentage = totalAmount / window.innerHeight * 100
				return {rect: item, percentageHidden: totalPercentage}
			})
			amountsOnPage.sort((a, b) => a.percentageHidden - b.percentageHidden)
			const visibleSorted = amountsOnPage.map(item => item.rect)
			const thumbIndex = thumbs.findIndex(thumb => thumb === visibleSorted[0])
			setCurrentThumb(thumbIndex)
		}
		updateCurrent()
		window.addEventListener('scroll', updateCurrent)
		window.addEventListener('resize', updateCurrent)
		return () => 
			window.removeEventListener('scroll', updateCurrent) &&
			window.removeEventListener('resize', updateCurrent)
	}, [refs])

	return (
		<>	
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<Logo noSpace={true} />
			<BlurFilter />
			<Projects>
				{content?.modules.map((module, i) => (
					<ProjectThumb 
						isFirst={i === 0} 
						ref={refs.current[i]} 
						key={module._key} 
						project={module.project} 
						alignment={module.alignment} 
						onHover={() => null}
						i={i}
					/>
				))}
			</Projects>
			
			<BrandImage css={css`padding: 0 var(--s);`}/>
			<DataViewer data={content} name="content"/>
		</>
	)
}

const Projects = styled(Section)`
	${tablet}{
		margin-top: 26vw;
	}
	${mobile}{
		margin-top: 40vw;
	}
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
      content {
				modules{
					_key
					alignment
					project {
						_type
						title
						slug {
							current
						}
						content{
							subtitle
							thumbnailVideo{
							asset{
									url
								}
							}
							thumbnailImage{
								...imageWithAlt
							}
						}
					}
				}
        seo{
					...seo
				}
      }
    }
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
