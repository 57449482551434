/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { isMobile } from 'react-device-detect'
import { Link } from 'gatsby'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import { useSiteState } from '~context/siteContext'

const ProjectThumb = React.forwardRef(({ className, project, alignment, onHover, isFirst, i }, ref) => {

	const [hovering, setHovering] = useState(false)
	const [videoReady, setVideoReady] = useState(false)
	const videoRef = useRef()
	const [siteState, setSiteState] = useSiteState()

	if(!project) return null

	useEffect(() => {
		if(videoRef?.current?.readyState >= videoRef?.current?.HAVE_FUTURE_DATA){
			setVideoReady(true)
		}
		if(hovering && videoRef?.current) {
			videoRef?.current?.play()
		}
		else if(videoRef?.current) {
			videoRef?.current?.pause()
		}
		if(hovering){
			window.setTimeout(() => {
				onHover([project.title, project.content?.subtitle])
			}, 0)
		} else {
			onHover(null)
		}
	}, [hovering])

	return (
		<Wrap 
			className={className}
			onMouseEnter={() => window.innerWidth > 1024 ? setHovering(true) : null}
			onMouseMove={() => window.innerWidth > 1024 ? setHovering(true) : null}
			onMouseLeave={() => setHovering(false)}
			alignment={alignment}
			to={resolveLink(project)}
			onClick={() => setSiteState(prevState => ({...prevState, projectTitleLocked: true}))}
			ref={ref}
			css={css`
				${isFirst && `
					@media(min-width: 1024px){
					margin-bottom: 0;
					height: 100vh;
					display: flex;
					align-items: center;
					min-height: 700px;
					`
		}}
			`}
		>
			<Media css={css`
				/* margin-bottom: ${isFirst ? '13vw' : '0'}; */
				width: 100%;
			`}>
				{!isMobile && project?.content?.thumbnailVideo ?
					<Video ref={videoRef} playsInline muted loop onCanPlay={() => setVideoReady(true)} css={css`
					transition: opacity 0.6s;
					opacity: ${videoReady ? 1 : 0};
				`}>
						<source src={project?.content?.thumbnailVideo?.asset?.url} />
					</Video>
					: <Image image={project?.content?.thumbnailImage} maxWidth={2000} />
				}
			</Media>
			<Text alignment={alignment}>
				<h2>
					{project.title}
					{project.content?.subtitle &&
						<>
							<br/>
							{project.content?.subtitle}
						</>
					}
				</h2>
			</Text>
		</Wrap>
	)
})

const wrapAlignment = props => {
	if(props.alignment === 'left'){
		return css`
			grid-column: 1 / 9;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else if(props.alignment === 'right'){
		return css`
			grid-column: 5 / 13;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else if(props.alignment === 'fullWidth'){
		return css`
			grid-column: span 12;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else {
		// center align
		return css`
			grid-column: 3 / 11;
			${mobile}{
				grid-column: span 12;
			}
		`
	}
}

const Wrap = styled(Link)`
	position: relative;
	/* matched to the height of the logo */
	/* margin-bottom: calc((100vw - 20px) * 0.1295); */
	margin-bottom: 13vw;
	${wrapAlignment}
`

const Video = styled.video`
	width: 100%;
	display: block;
`

const Text = styled.h2`
	margin-top: var(--s);
	display: none;
	${tablet}{
		display: block;
	}
`

const Media = styled.div`
	background: #675E3C;
`

ProjectThumb.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
	alignment: PropTypes.string,
	onHover: PropTypes.func,
	isFirst: PropTypes.bool,
	i: PropTypes.number,
}

export default ProjectThumb