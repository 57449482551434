/* eslint-disable react/prop-types */
import React from 'react'

export const Face = ({ className }) => (
	<svg className={className} viewBox="0 0 99 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="20" cy="20" r="20" fill="currentColor"/>
		<path d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z" fill="currentColor"/>
		<circle cx="79" cy="20" r="20" fill="currentColor"/>
	</svg>
)

export const BrandImage = ({ className }) => (
	<svg className={className} viewBox="0 0 1402 789" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_225_839)">
			<g opacity="0.8">
				<path opacity="0.8" d="M1126.54 375.577H261.918V375.614H0V378.51H261.918V378.546H1126.54V378.51H1402V375.614H1126.54V375.577Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 363.468H261.918V363.599H0V366.488H261.918V366.619H1126.54V366.488H1402V363.599H1126.54V363.468Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 351.366H261.918V351.585H0V354.474H261.918V354.693H1126.54V354.474H1402V351.585H1126.54V351.366Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 342.773H644.884C640.405 341.727 635.975 340.555 631.594 339.257H261.918V339.563H0V342.481H261.918V342.773Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 339.257H756.854C752.472 340.56 748.045 341.732 743.571 342.773H1126.54V342.459H1402V339.541H1126.54V339.257Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 327.148H788.45C785.712 328.439 782.951 329.665 780.169 330.846H1126.51V330.445H1402V327.527H1126.54V327.148Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 330.846H608.264C605.481 329.665 602.714 328.439 599.976 327.148H261.918V327.549H0V330.467H261.918V330.846Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 318.919H584.079C581.869 317.665 579.678 316.371 577.507 315.039H261.918V315.535H0V318.452H261.918V318.919Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 315.039H810.947C808.771 316.366 806.581 317.66 804.375 318.919H1126.54V318.431H1402V315.513H1126.54V315.039Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 307H565.181C563.278 305.672 561.387 304.318 559.507 302.937H261.918V303.52H0V306.438H261.918V307Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 302.937H828.932C827.056 304.315 825.172 305.672 823.259 307H1126.54V306.416H1402V303.498H1126.54V302.937Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 290.828H844.121C842.451 292.287 840.764 293.702 839.061 295.073H1126.55V294.395H1402V291.477H1126.54V290.828Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 295.073H549.409C547.71 293.677 546.023 292.262 544.348 290.828H261.918V291.506H0V294.424H261.918V295.073Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 283.153H535.812C534.264 281.695 532.728 280.216 531.205 278.718H261.918V279.492H0V282.409H261.918V283.153Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 278.718H857.265C855.746 280.221 854.21 281.699 852.657 283.153H1126.56V282.38H1402V279.462H1126.54V278.718Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 266.609H868.773C867.38 268.17 865.969 269.71 864.537 271.227H1126.51V270.366H1402V267.448H1126.54V266.609Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 271.227H523.888C522.457 269.71 521.045 268.17 519.653 266.609H261.889V267.47H0V270.388H261.918V271.227Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 254.507H879.076C877.786 256.117 876.474 257.715 875.14 259.3H1126.54V258.352H1402V255.434H1126.54V254.507Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 259.3H513.336C512.012 257.719 510.7 256.122 509.401 254.507H261.918V255.456H0V258.373H261.918V259.3Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 247.38H503.844C502.61 245.732 501.383 244.076 500.193 242.398H261.918V243.441H0V246.359H261.918V247.38Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 242.435H888.262C887.057 244.112 885.838 245.768 884.611 247.417H1126.55V246.374H1402V243.456H1126.54V242.435Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 235.454H495.337C494.183 233.747 493.044 232.025 491.919 230.289H261.904V231.427H0V234.345H261.918V235.454Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 230.289H896.521C895.396 232.025 894.257 233.747 893.103 235.454H1126.53V234.323H1402V231.405H1126.54V230.289Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 223.534H487.67C486.589 221.761 485.53 219.981 484.479 218.18H261.918V219.412H0V222.33H261.918V223.534Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 218.18H903.998C902.954 219.981 901.895 221.761 900.814 223.534H1126.57V222.301H1402V219.383H1126.54V218.18Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 206.078H910.672C909.694 207.931 908.708 209.777 907.7 211.607H1126.54V210.287H1402V207.369H1126.54V206.078Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 211.607H480.747C479.747 209.777 478.754 207.96 477.783 206.078H261.918V207.42H0V210.338H261.918V211.607Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 199.681H474.519C473.577 197.791 472.664 195.887 471.758 193.969H261.918V195.377H0V198.295H261.918V199.681Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 193.969H916.696C915.791 195.885 914.868 197.789 913.929 199.681H1126.54V198.273H1402V195.355H1126.54V193.969Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 181.889H922.078C921.246 183.863 920.394 185.83 919.522 187.79H1126.52V186.265H1402V183.348H1126.54V181.889Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 187.761H468.911C468.044 185.816 467.192 183.849 466.355 181.86H261.897V183.348H0V186.266H261.918V187.761Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 175.834H463.887C463.083 173.821 462.297 171.793 461.528 169.75H261.918V171.348H0V174.266H261.918V175.834Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 169.75H926.919C926.15 171.793 925.366 173.821 924.568 175.834H1126.54V174.244H1402V171.326H1126.54V169.75Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 163.915H459.396C458.661 161.834 457.931 159.745 457.205 157.649H261.918V159.334H0V162.252H261.918V163.915Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 157.649H931.206C930.475 159.75 929.789 161.836 929.015 163.915H1126.5V162.194H1402V159.276H1126.54V157.649Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 151.981H455.424C454.752 149.846 454.107 147.696 453.489 145.532H261.918V147.312H0V150.23H261.918V151.981Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 145.54H934.966C934.338 147.699 933.693 149.848 933.031 151.988H1126.54V150.208H1402V147.29H1126.54V145.54Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 133.43H938.223C937.668 135.657 937.091 137.868 936.492 140.061H1126.54V138.194H1402V135.276H1126.54V133.43Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 140.061H451.999C451.4 137.868 450.823 135.657 450.268 133.43H261.918V135.298H0V138.215H261.918V140.061Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 121.343H941.012C940.525 123.624 940.022 125.898 939.501 128.164H1126.54V126.202H1402V123.284H1126.54V121.343Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 128.142H448.954C448.428 125.88 447.917 123.605 447.435 121.321H261.918V123.284H0V126.201H261.918V128.142Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 116.237H446.376C445.924 113.917 445.493 111.585 445.084 109.241H261.918V111.291H0V114.209H261.918V116.237Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 109.219H943.364C942.955 111.558 942.524 113.89 942.071 116.215H1126.54V114.165H1402V111.247H1126.54V109.219Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 104.295H444.281C443.901 101.91 443.55 99.5098 443.215 97.1099H261.918V99.2545H0V102.172H261.918V104.295Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 97.1099H945.24C944.904 99.5098 944.554 101.91 944.167 104.295H1126.54V102.15H1402V99.2326H1126.54V97.1099Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 92.3686H442.587C442.28 89.937 442 87.4812 441.747 85.001H261.918V87.2404H0V90.1583H261.918V92.3686Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 85.001H946.7C946.452 87.4666 946.167 89.9249 945.868 92.3686H1126.54V90.1291H1402V87.2113H1126.54V85.001Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 80.4416H441.309C441.08 77.9371 440.88 75.4204 440.71 72.8916H261.918V75.2259H0V78.1438H261.918V80.4416Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 72.8916H947.738C947.567 75.4204 947.37 77.9371 947.146 80.4416H1126.54V78.1146H1402V75.1967H1126.54V72.8916Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 60.7974H948.365C948.278 63.3797 948.161 65.962 948.022 68.5297H1126.55V66.1006H1402V63.1827H1126.54V60.7974Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 68.5224H440.447C440.301 65.9547 440.184 63.3724 440.097 60.79H261.926V63.2046H0V66.1224H261.918V68.5224Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 56.5954H439.965C439.907 53.9693 439.87 51.3286 439.87 48.6807H261.918V51.19H0V54.1079H261.918V56.5954Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 48.6807H948.577C948.577 51.3262 948.548 53.9644 948.49 56.5954H1126.54V54.086H1402V51.1682H1126.54V48.6807Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 36.5718H948.584V44.6762H1126.54V42.072H1402V39.176H1126.54V36.5718Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 44.6762H439.87V36.5718H261.918V39.176H0V42.072H261.918V44.6762Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 32.7492H439.87V24.4624H261.918V27.1614H0V30.0574H261.918V32.7492Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 24.4624H948.584V32.7492H1126.54V30.0574H1402V27.1614H1126.54V24.4624Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 12.3535H948.584V20.8226H1126.54V18.0361H1402V15.1474H1126.54V12.3535Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 20.8226H439.87V12.3535H261.918V15.1474H0V18.0361H261.918V20.8226Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M261.918 8.90295H439.87V0.251465H261.918V3.12557H0V6.02155H261.918V8.90295Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M340.759 780.176H0V788.251H340.759V785.421H439.87V783.014H340.759V780.176Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1047.7 783.014H948.584V785.421H1047.7V788.251H1402V780.176H1047.7V783.014Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1047.7 771.05H948.584V773.465H1047.7V776.2H1402V768.315H1047.7V771.05Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M340.759 768.315H0V776.2H340.759V773.465H439.87V771.05H340.759V768.315Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M340.759 756.447H0V764.15H340.759V761.502H439.87V759.087H340.759V756.447Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1047.7 759.087H948.584V761.502H1047.7V764.15H1402V756.447H1047.7V759.087Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M340.781 744.578H0V752.092H340.759V749.546H439.87V747.131H340.759C340.759 746.278 340.766 745.424 340.781 744.578Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1047.69 747.131H948.577C948.577 747.197 948.577 747.27 948.577 747.343V749.531H1047.69V752.077H1402V744.563H1047.67C1047.68 745.424 1047.69 746.278 1047.69 747.131Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1047.4 735.168H948.358C948.358 735.97 948.409 736.78 948.431 737.582H1047.5C1047.5 738.399 1047.56 739.224 1047.59 740.041H1402V732.746H1047.27C1047.32 733.527 1047.36 734.351 1047.4 735.168Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M341.175 732.71H0V740.005H340.861C340.861 739.188 340.92 738.363 340.949 737.546H440.016C440.016 736.744 440.067 735.934 440.097 735.132H341.051C341.095 734.351 341.131 733.527 341.175 732.71Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1046.56 723.212H947.759C947.818 724.014 947.861 724.817 947.913 725.627H1046.78C1046.84 726.414 1046.91 727.202 1046.97 727.99H1402V720.841H1046.33C1046.39 721.629 1046.48 722.424 1046.56 723.212Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M342.125 720.841H0V727.99H341.482C341.54 727.202 341.613 726.414 341.679 725.627H440.535L440.688 723.212H341.891C341.964 722.424 342.037 721.629 342.125 720.841Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1045.15 711.249H946.744C946.825 712.051 946.898 712.861 946.978 713.664H1045.48C1045.58 714.393 1045.68 715.181 1045.78 715.94H1402V708.973H1044.83C1044.93 709.732 1045.05 710.49 1045.15 711.249Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M343.621 708.973H0V715.94H342.679C342.767 715.21 342.869 714.422 342.972 713.664H441.477C441.55 712.861 441.623 712.051 441.71 711.249H343.3C343.402 710.49 343.512 709.732 343.621 708.973Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M345.695 697.104H0V703.889H344.447C344.571 703.159 344.717 702.43 344.848 701.7H442.791C442.901 700.898 443.025 700.095 443.134 699.293H345.286C345.432 698.563 345.556 697.834 345.695 697.104Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1043.15 699.293H945.306C945.415 700.095 945.54 700.898 945.642 701.7H1043.58C1043.72 702.43 1043.86 703.159 1043.99 703.889H1402V697.104H1042.74C1042.9 697.834 1043.02 698.563 1043.15 699.293Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M348.353 685.222H0V691.787H346.805C346.959 691.057 347.134 690.394 347.294 689.701H444.573C444.704 688.898 444.828 688.089 444.967 687.286H347.849C348.025 686.637 348.178 685.951 348.353 685.222Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1040.59 687.33H943.473C943.612 688.132 943.729 688.942 943.86 689.745H1041.14C1041.3 690.438 1041.47 691.131 1041.63 691.831H1402V685.266H1040.1C1040.27 685.951 1040.42 686.637 1040.59 687.33Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1037.43 675.366H941.195C941.363 676.176 941.531 676.979 941.691 677.781H1038.12C1038.31 678.452 1038.51 679.109 1038.68 679.78H1402V673.368H1036.9C1037.06 674.031 1037.23 674.703 1037.43 675.366Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M351.595 673.368H0V679.78H349.77C349.952 679.109 350.157 678.452 350.339 677.781H446.756L447.26 675.366H351.026C351.23 674.703 351.398 674.031 351.595 673.368Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1033.65 663.41H938.471C938.675 664.213 938.88 665.015 939.07 665.825H1034.46C1034.67 666.459 1034.9 667.087 1035.1 667.729H1402V661.506H1033C1033.24 662.134 1033.43 662.776 1033.65 663.41Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M355.444 661.506H0V667.714H353.348C353.56 667.072 353.779 666.445 353.99 665.81H449.385C449.575 665.001 449.779 664.198 449.976 663.396H354.808C355.02 662.776 355.224 662.134 355.444 661.506Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M359.942 649.638H0V655.678H357.576C357.802 655.065 358.05 654.467 358.306 653.862H452.503C452.729 653.059 452.941 652.25 453.167 651.447H359.263C359.474 650.849 359.701 650.207 359.942 649.638Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1029.22 651.447H935.302C935.528 652.25 935.74 653.059 935.967 653.862H1030.16C1030.4 654.467 1030.65 655.065 1030.89 655.678H1402V649.638H1028.51C1028.75 650.207 1028.98 650.849 1029.22 651.447Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1024.13 639.491H931.622C931.884 640.294 932.147 641.096 932.403 641.906H1025.21C1025.47 642.475 1025.74 643.044 1025.99 643.62H1402V637.784H1023.36C1023.63 638.339 1023.87 638.915 1024.13 639.491Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M365.104 637.806H0V643.642H362.468C362.716 643.066 362.979 642.497 363.235 641.928H456.044C456.307 641.118 456.563 640.315 456.826 639.513H364.316C364.578 638.915 364.827 638.339 365.104 637.806Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M370.946 625.901H0V631.569H368.025C368.295 631.022 368.58 630.49 368.857 629.943H460.097C460.389 629.133 460.696 628.33 460.995 627.528H370.106C370.391 626.988 370.654 626.441 370.946 625.901Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1018.34 627.528H927.459C927.759 628.33 928.058 629.133 928.35 629.943H1019.57C1019.84 630.49 1020.12 631.022 1020.39 631.569H1402V625.901H1017.52L1018.34 627.528Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1011.83 615.572H922.742C923.071 616.374 923.414 617.177 923.736 617.979H1013.17C1013.46 618.497 1013.76 619 1014.05 619.518H1402V614.033H1010.95C1011.25 614.543 1011.53 615.061 1011.83 615.572Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M377.518 614.033H0V619.518H374.378C374.663 619 374.962 618.497 375.254 617.979H464.683C465.011 617.177 465.347 616.374 465.683 615.572H376.598C376.919 615.061 377.204 614.543 377.518 614.033Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1004.52 603.609H917.521C917.894 604.411 918.252 605.213 918.624 606.023H1006.06C1006.37 606.505 1006.68 606.979 1006.98 607.482H1402.02V602.179H1003.6C1003.91 602.646 1004.21 603.127 1004.52 603.609Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M384.856 602.165H0V607.468H381.468C381.768 606.979 382.089 606.505 382.388 606.009H469.831C470.196 605.199 470.561 604.397 470.933 603.594H383.929C384.243 603.127 384.542 602.646 384.856 602.165Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M996.377 591.645H911.672C912.081 592.448 912.483 593.258 912.885 594.06H998.093L999.057 595.409H1402V590.303H995.42C995.749 590.748 996.055 591.2 996.377 591.645Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M393.027 590.296H0V595.402H389.398C389.712 594.95 390.041 594.505 390.355 594.053H475.563C475.972 593.25 476.366 592.441 476.782 591.638H392.078C392.392 591.2 392.706 590.748 393.027 590.296Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M987.337 579.69H905.166C905.626 580.485 906.064 581.302 906.517 582.104H989.242C989.571 582.52 989.907 582.936 990.236 583.359H1402V578.428H986.351L987.337 579.69Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M402.104 578.428H0V583.359H398.219C398.54 582.936 398.884 582.52 399.212 582.104H481.938C482.383 581.302 482.828 580.485 483.281 579.69H401.111C401.447 579.274 401.768 578.851 402.104 578.428Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M977.311 567.726H897.952C898.456 568.529 898.945 569.338 899.412 570.141H979.385C979.721 570.535 980.071 570.914 980.407 571.308H1402V566.559H976.289C976.639 566.946 976.968 567.34 977.311 567.726Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M412.159 566.559H0V571.308H408.011C408.347 570.914 408.69 570.535 409.026 570.141H489.006C489.502 569.338 489.992 568.529 490.503 567.726H411.107L412.159 566.559Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M966.19 555.77H889.934C890.504 556.565 891.059 557.375 891.614 558.185H968.534C968.877 558.542 969.264 558.914 969.578 559.257H1402V554.698H965.153C965.503 555.048 965.839 555.413 966.19 555.77Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M423.302 554.698H0V559.257H418.877C419.22 558.892 419.607 558.527 419.921 558.185H496.841C497.396 557.375 497.951 556.565 498.513 555.77H422.258C422.608 555.413 422.951 555.048 423.302 554.698Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M953.835 543.807H881.033C881.668 544.61 882.296 545.412 882.924 546.222H956.434C956.785 546.55 957.164 546.871 957.493 547.206H1402V542.83H952.783C953.141 543.151 953.484 543.486 953.835 543.807Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M435.671 542.83H0V547.206H430.962C431.305 546.871 431.692 546.55 432.013 546.222H505.53C506.151 545.412 506.786 544.61 507.414 543.807H434.613C434.97 543.486 435.314 543.151 435.671 542.83Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M449.458 530.961H0V535.148H444.412L445.478 534.258H515.228C515.919 533.451 516.618 532.648 517.323 531.851H448.384L449.458 530.961Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M940.063 531.851H871.139C871.84 532.644 872.538 533.446 873.234 534.258H942.984C943.342 534.557 943.714 534.849 944.057 535.148H1402V530.961H938.99C939.355 531.253 939.705 531.552 940.063 531.851Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M464.909 519.093H0V523.098H459.484C459.834 522.828 460.214 522.572 460.557 522.302H526.086C526.867 521.493 527.641 520.683 528.423 519.888H463.828L464.909 519.093Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M924.626 519.888H860.025C860.813 520.683 861.587 521.493 862.361 522.302H927.89L928.971 523.098H1402V519.093H923.546L924.626 519.888Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M907.16 507.961H847.451C848.342 508.763 849.23 509.568 850.116 510.375H910.869L911.95 511.105H1402V507.231H906.072C906.437 507.458 906.795 507.698 907.16 507.961Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M482.376 507.231H0V511.054H476.497C476.855 510.813 477.228 510.587 477.585 510.324H538.331C539.217 509.517 540.108 508.712 541.004 507.91H481.295C481.66 507.698 482.01 507.458 482.376 507.231Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M502.442 495.355H0V499.003H495.972C496.33 498.791 496.702 498.594 497.06 498.39H552.33C553.359 497.573 554.396 496.778 555.44 495.976H501.354L502.442 495.355Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M887.101 495.969H833.014C834.051 496.771 835.095 497.566 836.125 498.383H891.394C891.752 498.587 892.125 498.784 892.482 498.996H1402V495.349H886.013L887.101 495.969Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M525.998 483.487H0V486.938H518.703L519.799 486.42H568.723C569.942 485.61 571.162 484.8 572.374 484.005H524.91L525.998 483.487Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M863.552 484.005H816.088C817.315 484.8 818.534 485.61 819.739 486.42H868.663L869.766 486.938H1402V483.487H862.456L863.552 484.005Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M554.863 471.619H0V474.887H546.196L547.32 474.464H588.548C590.074 473.639 591.615 472.837 593.156 472.049H553.739L554.863 471.619Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M834.716 472.049H795.284C796.832 472.837 798.366 473.639 799.899 474.464H841.068L842.2 474.887H1402V471.619H833.584L834.716 472.049Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M593.513 459.758H0V462.836H582.02L583.232 462.501H614.375C616.522 461.659 618.686 460.854 620.867 460.086H592.236C592.659 459.977 593.083 459.86 593.513 459.758Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M796.219 460.086H767.58C769.761 460.859 771.928 461.664 774.079 462.501H805.223L806.427 462.836H1402V459.758H794.934C795.365 459.86 795.788 459.977 796.219 460.086Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M718.525 448.144H711.785C718.269 448.665 724.685 449.47 731.033 450.559H745.389C746.068 450.639 746.755 450.712 747.434 450.8H1402V447.882H714.334C715.736 447.955 717.13 448.05 718.525 448.144Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M674.114 447.889H0V450.807H641.013C641.692 450.72 642.379 450.647 643.058 450.566H657.414C663.742 449.477 670.158 448.672 676.662 448.152H669.922C671.317 448.05 672.712 447.955 674.114 447.889Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1402 436.021H0V438.735H1402V436.021Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1402 424.152H0V426.676H1402V424.152Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1402 411.656H0V414.552H1402V411.656Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1402 399.643H0V402.538H1402V399.643Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1402 387.628H0V390.524H1402V387.628Z" fill="#1C1C1C"/>
				<path opacity="0.8" d="M1126.54 3.79651V0.331543H948.584V8.90279H1126.54V6.02139H1402V3.79651H1126.54Z" fill="#1C1C1C"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_225_839">
				<rect width="1402" height="788" fill="white" transform="translate(0 0.251465)"/>
			</clipPath>
		</defs>
	</svg>

)
